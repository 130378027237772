import type { FproductPaymentMethodData, NicedayProductGatewayData } from './api'

export enum CardType {
  VISA = 1,
  MASTER_CARD = 2,
  JCB = 3,
  UNION_PAY = 4,
  AMEX = 5,
  DISCOVER = 6,
  DINERS = 7,
}

export const CardTypeNameMap: Record<number, string> = {
  [CardType.VISA]: 'visa',
  [CardType.MASTER_CARD]: 'mastercard',
  [CardType.JCB]: 'jcb',
  [CardType.UNION_PAY]: 'unionpay',
  [CardType.AMEX]: 'amex',
  [CardType.DISCOVER]: 'discover',
  [CardType.DINERS]: 'diners',
}

export type BookingPaymentQuery = {
  /** @example '3171471576759' */
  product_id: string | number
  /** @example '204' or 204 */
  amount: string | number
  /** @example '0' */
  currency_id: string | number
  /** @example '2023-09-02 14:30:00' */
  booking_time: string
  /** @example false */
  use_fun_coin: boolean
  /** @example '123431' */
  code_id?: string | number
  /** @example 1 | 0 */
  promo_usage?: 1 | 0
}

export type GatewaysQuery = {
  sale_region_id?: number
}

/** 收款類型 */
export enum PaymentMethodsType {
  NONE = -2,
  SAVED_CREDIT_CARD,
  NEW_CREDIT_CARD,
  /** @deprecated Web 尚未實作  */
  GOOGLE_PAY,
  /** @deprecated Web 尚未實作  */
  APPLY_PAY,
  LINE_PAY,
  /** @deprecated Web 尚未實作  */
  FPX,
  /** @deprecated Web 尚未實作  */
  PI_WALLET,
  /** @deprecated Web 尚未實作  */
  OCTOPUS,
  GRAB_PAY,
  ATM_TO_MERCHANT,
  PROMPT_PAY,
  /** @deprecated TNG 不顯示 payment options */
  TNG,
  /** 分期付款 3 期 */
  THREE_INSTALLMENTS,
}

export interface PaymentMethodData extends Omit<FproductPaymentMethodData, 'type'> {
  type?: PaymentMethodsType
}

export interface ProductGatewayData extends Omit<NicedayProductGatewayData, 'payment_methods'> {
  payment_methods?: PaymentMethodData[]
}
