import useApiClient from './api-client'
import type { BookingPaymentQuery, GatewaysQuery } from '@/types/payment'
import type {
  FpaymentCreditCardInfo,
  NicedayProductGatewayData,
  PaymentPaymentAmountData,
  FpaymentPaymentReqV2,
  FpaymentDocPaymentURLResp,
} from '@/types/api'
import { generateSearchParams } from '@/utils/common'

export const getPayment = async () => {
  const api = useApiClient<FpaymentCreditCardInfo[]>()
  const result = await api('/payment', { method: 'GET' })

  return result
}

export const deleteCreditCard = async (cardId: number) => {
  const api = useApiClient()
  const result = await api(`/payment/creditcard/${cardId}`, {
    method: 'DELETE',
  })

  return result
}

export const getBookingPayment = async (params: BookingPaymentQuery) => {
  const api = useApiClient<PaymentPaymentAmountData>()
  const { rawObject } = generateSearchParams(params as any)
  const result = await api('/payment/amount', { query: rawObject })
  return result.data
}

export const getGateways = async (productId: number | string, params: GatewaysQuery) => {
  const api = useApiClient<NicedayProductGatewayData>()
  const result = await api(`/product/${productId}/gateway`, { method: 'GET', query: params })

  return result.data
}

export const createCreditPayment = async (params: FpaymentPaymentReqV2) => {
  const api = useApiClient<FpaymentDocPaymentURLResp>()
  const result = await api(`/payment/pay-by-credit`, { method: 'POST', body: params })

  return result
}

export const createCreditInstallmentPayment = async (params: FpaymentPaymentReqV2) => {
  const api = useApiClient<FpaymentDocPaymentURLResp>()
  const result = await api(`/payment/pay-by-credit-installment`, { method: 'POST', body: params })

  return result
}

// @TODO: change related types
export const createLinePayPayment = async (params: FpaymentPaymentReqV2) => {
  const api = useApiClient<FpaymentDocPaymentURLResp>()
  const result = await api(`/payment/pay-by-line`, { method: 'POST', body: params })

  return result
}

export const createZeroAmountPayment = async (params: Pick<FpaymentPaymentReqV2, 'orderid' | 'code_id' | 'promo_usage'>) => {
  const api = useApiClient<FpaymentDocPaymentURLResp>()
  const result = await api(`/payment/none`, { method: 'POST', body: params })

  return result
}
